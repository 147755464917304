(function (ng)
{
    'user strict';

    var init = function () {
        ng
        .module('URLModule', [])
        .service('URLGenerator', [urlGenerator])
    },

    urlGenerator = function () {
        
        this.addQueryStringToURL = function (url, queryName, value)
        {
            if (url != null && url != "")
            {
                var newURL = "";
                var queryString = "";

                var myregexp = /([#]|^)([^#?]+)\/([^#]*)$/m;
                var match = myregexp.exec(url);
                if (match != null)
                {
                    newURL = match[2];
                    queryString = match[3];
                }

                if (queryName != "" && queryName != null && (value + "") != "" && value != null)
                {
                    var newURL = "";
                    var queryString = "";

                    var myregexp = /([#]|^)([^#?]+)\/([^#]*)$/m;
                    var match = myregexp.exec(url);
                    if (match != null)
                    {
                        newURL = match[2];
                        queryString = match[3];
                    }

                    if (queryString == "")
                    {
                        queryString = queryName + "=" + value;
                    }
                    else
                    {
                        if (queryString.indexOf(queryName + "=") != -1)
                        {
                            var regexString = new RegExp("(^|&)(" + queryName + ")=([^&]+)?", "mg");
                            queryString = queryString.replace(regexString, "$1$2=" + value);
                        }
                        else
                        {
                            queryString += "&" + queryName + "=" + value;
                        }
                    }
                }

                if (queryString != "")
                {
                    newURL += "/" + queryString;
                }
                return newURL;
            }
        };
    };

    init();
})(this.angular);