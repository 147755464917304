/// <reference path="../../../typings/jquery/jquery.d.ts" />

/*
 * InterfaceManager v1.0
 */

(function (root, $) 
{
 'use strict';
 
 var
 
  /**
   * Initialize
   */
  init = function () 
  {
    if(!root.iShiaLibrary) root.iShiaLibrary = {};
    if(!root.iShiaLibrary.Managers) root.iShiaLibrary.Managers = {};
    if(!root.iShiaLibrary.Managers.InterfaceManager) root.iShiaLibrary.Managers.InterfaceManager = {};
    
    root.iShiaLibrary.Managers.InterfaceManager.reZIndex = reZIndex;
    root.iShiaLibrary.Managers.InterfaceManager.moveToFront = moveToFront;
    root.iShiaLibrary.Managers.InterfaceManager.moveElements = moveElements;
    root.iShiaLibrary.Managers.InterfaceManager.sortByPositionLeft = sortByPositionLeft;
    root.iShiaLibrary.Managers.InterfaceManager.reOrderTimeline = reOrderTimeline;
  },

  reZIndex = function(selectedElement, otherElements, startNo)
  {
    var zindex = startNo;

    $(otherElements).each(function() 
    {
      $(this).css("z-index", zindex++);
    });

    $(selectedElement).css("z-index", zindex);
  },

  moveToFront = function(selectedElement, otherElements)
  {
    var max = 0;

    // Find the highest z-index
    $(otherElements).each(function() 
    {
      // Find the current z-index value
      var z = parseInt($( this ).css("z-index"), 10);
      // Keep either the current max, or the current z-index, whichever is higher
      max = Math.max(max, z);
    });

    // Set the box that was clicked to the highest z-index plus one
    $(selectedElement).css("z-index", max + 1); 
  },
  
  moveElements = function(top, left, elements)
  {
    $(elements).each(function() 
    {
      $(this).css({top: parseInt($(this).css("top"), 10) + top});
      $(this).css({left: parseInt($(this).css("left"), 10) + left});
    });
  },
  
  sortByPositionLeft = function (a, b)
  {
    return ($(b).position().left) < ($(a).position().left) ? 1 : -1;  
  },
  
  reOrderTimeline = function (sortedChild)
  {
    for (var index = 0; index < sortedChild.length; index++)
      {
        var currentElement = $(sortedChild[index]);
        var prevElement = $(sortedChild[index - 1]);
        
        var currentElementStart = currentElement.position().left;
        var currentElementEnd = currentElementStart + currentElement.width();
        
        if(index > 0)
        {
          var prevElementStart = prevElement.position().left;
          var prevElementEnd = prevElementStart + prevElement.width();
          
          if(currentElementStart < prevElementEnd)
          {
            currentElement.css('left', prevElementEnd);
          }
        }
      }
  }

 ;
 init();
})(this, this.jQuery);