/*
 * LocalizationManager (L17r) v1.0
 */

(function (root) 
	{
  'use strict';

  var
  
  /**
   * Initialize
   */
  init = function () 
  {
    if(root.L17r && root.L17r.currentLang)
    {
      console.warn('L17r (LocalizationManager) already defined!');
      return false;
    }
    
    var langs = root.L17r ? root.L17r.langs : {};

    root.L17r = localizeText;
    root.L17r.setCurrentLang = setCurrentLang;
    root.L17r.langs = langs;
    root.L17r.currentLangCode = -1;

    // Automaticaly get from htmll lang but you can chnage it manually
    if(document.head.parentElement && document.head.parentElement.attributes.lang)
    {
      root.L17r.setCurrentLang(document.head.parentElement.attributes.lang.value.trim());
    }
    else
    {
      root.L17r.setCurrentLang('en');
    }
  },

  setCurrentLang = function (lang)
  {
    if (lang)
    {
      lang = lang.toLowerCase().trim();

      root.L17r.currentLang = lang;
      root.L17r.currentLangCode = getLangCode(lang);
    }
  },

  /**
   * [localizeText description]
   * @param  {[type]} text [description]
   * @return {[type]}      [description]
   */
  localizeText = function (text, ulang)
  {
    var
    key = text.toUpperCase(),
    lang = ulang || root.L17r.currentLang
    ;
    lang = lang.toLowerCase().trim();

    if (root.L17r.langs[lang] && root.L17r.langs[lang][key])
    {
      return root.L17r.langs[lang][key];
    }
    else
    {
      return key;
    }
  },

  getLangCode = function (lang)
  {
    var retVal = -1;

    switch (lang)
    {
      case 'fa':
      case 'farsi':
        retVal = 12;
        break;

      case 'ar':
      case 'arabic':
        retVal = 11;
        break;

      case 'en':
      case 'english':
        retVal = 13;
        break;
		
	  case 'in':
      case 'indonesian':
        retVal = 14;
        break;
		
	  case 'fr':
      case 'french':
        retVal = 15;
        break;
		
	  case 'ru':
      case 'russian':
        retVal = 16;
        break;

	  case 'sw':
      case 'swahili':
        retVal = 17;
        break;
		
	  case 'ms':
      case 'malay':
        retVal = 18;
        break;
		
	  case 'ur':
      case 'Urdu':
        retVal = 19;
        break;
		
	  case 'tr':
      case 'Turkish':
        retVal = 20;
        break;
		
      case 'az':
      case 'azerbaijani':
        retVal = 21;
        break;
		
	  case 'ps':
	  case 'Pashto':
        retVal = 25;
        break;
		
      case 'bangali':
        retVal = 26;
        break;

      case 'bn':
      case 'bangla':
        retVal = 26;
        break;        

      default:
        retVal = 13;
        break;
    }

    return retVal;
  }

  ;
  init();
})(this);