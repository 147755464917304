/*
 * EntityManager v1.0
 */

(function (root) 
	{
  'use strict';

  var
  
  /**
   * Initialize
   */
  init = function () 
  {
    if(!root.iShiaLibrary) root.iShiaLibrary = {};
    if(!root.iShiaLibrary.Managers) root.iShiaLibrary.Managers = {};
    if(!root.iShiaLibrary.Managers.EntityManager) root.iShiaLibrary.Managers.EntityManager = {};

    root.iShiaLibrary.Managers.EntityManager.entityNoToName = entityNoToName;
  },

  entityNoToName = function(no)
  {
        switch(no)
        {
          case 0:
            return 'None';          
          case 1:
            return 'System';          
          case 2:
            return 'Account';          
          case 3:
            return 'Message';
          case 9:
            return 'Backup';
          case 10:
            return 'QuranChapter';
          case 11:
            return 'Quran';
          case 12:
            return 'NahjolBalagha';
          case 13:
            return 'SahifaSajjadia';
          case 14:
            return 'Supplication';          
          case 15:
            return 'Hadith';
          case 21:
            return 'Answer';
          case 22:
            return 'historicalEvent';
          case 23:
            return 'Request';
          case 24:
            return 'Clip';
          case 25:
            return 'MediaSRT';          
          case 26:
            return 'Book';          
          case 27:
            return 'EBook';
          case 28:
            return 'BookVolume';          
          case 29:
            return 'BookPart';
          case 30:
            return 'BookPage';
          case 31:
            return 'BookTOC';          
          case 32:
            return 'Glossary';
          case 33:
            return 'Website';
          case 34:
            return 'Webpage';          
          case 35:
            return 'Audio';
          case 36:
            return 'Poem';
          case 37:
            return 'Merit';
          case 38:
            return 'Abstract';
          case 39:
            return 'Dictionary';
          case 40:
            return 'Research';
          case 51:
            return 'Entry';
          case 52:
            return 'Person';
          case 53:
            return 'Source';
          case 54:
            return 'Place';
          case 55:
            return 'DateDef';
          case 56:
            return 'Dashborad';
          case 57:
            return 'Sentence';
          case 58:
            return 'TOC';
          case 59:
            return 'Fatwa';
          case 60:
            return 'Istifta';
          case 61:
            return 'Sdate';
          case 62:
            return 'MessageReply';
          case 63:
            return 'MessageReply_Group';
          case 64:
            return 'AhkamNews';
          case 65:
            return 'News';
          case 66:
            return 'PMG';
          case 67:
            return 'AppConfiguration';
          case 68:
            return 'Article';
          case 69:
            return 'Magazine';
          case 70:
            return 'MagazineIssue';
          case 71:
            return 'Notifications';
          case 72:
            return 'FeedBack';
          case 73:
            return 'Image';
          case 74:
            return 'Gallery';
          case 75:
            return 'EDoc';
          case 76:
            return 'AssessmentUser';
          case 77:
            return 'Meeting';
          case 78:
            return 'Configuration';
          case 79:
            return 'Forum';
          case 80:
            return 'Ceremony';
          case 81:
            return 'GeneralEntity';
          case 82:
            return 'Domain';
          case 83:
            return 'Resource';
          case 84:
            return 'Application';
          case 101:
            return 'Comment';
          case 102:
            return 'Rate';
          case 103:
            return 'ReportError';
          case 104:
            return 'AppExport';
          case 105:
            return 'Ticket';
          case 121:
            return 'Task';
          case 122:
            return 'Org';
          case 123:
            return 'project';
          case 124:
            return 'UserGroup';
          case 125:
            return 'RuleAssigned';
          case 126:
            return 'TaskGroup';
          case 127:
            return 'Work';          
          case 128:
            return 'Report';
    }
  }
  
  ;
  init();
})(this);