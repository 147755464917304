;(function(ng)
{
  'use strict';
  var
  init = function()
  {
    ng
    .module('iShiaPagination', [])
    .directive('ishiaPagination', [directive])
  },
  
  /**
   * [directive description]
   */
  directive = function($location)
  {
    var
    link = function ($scope, elem, attrs)
    {
      // a funtion to get variables from query strings
      var getQueryVariable = function(variable)
      {
        // var query = window.location.search.substring(1);
        var query = window.location.hash;
        var vars = query.split("&");
        for (var i=0;i<vars.length;i++)
        {
          var pair = vars[i].split("=");
          if(pair[0] == variable)
          {
            return pair[1];
          }
       }
       return(false);
      }

      $scope.$watch('currentPage', function(newValue, oldValue)
      {        
        var
          rawStart,
          rawEnd;
        $scope.pageArray = [];
        $scope.pageCount = Math.ceil($scope.rowsCount / $scope.rowPerPage);

        // populate directive with default values
        (ng.isUndefined($scope.currentPage)?$scope.currentPage = 1:'');

        var rawStart = $scope.currentPage;
        var halfStart = parseInt($scope.range / 2);

        if(halfStart >= rawStart)
          rawStart = 1;
        else
          rawStart -= halfStart;
        

        if(($scope.pageCount - rawStart) < $scope.range)
        {
          rawStart -= $scope.range - ($scope.pageCount - rawStart);
          if(rawStart<=0)
            rawStart = 1;
        }
       
        var rawEnd = rawStart + $scope.range;
        var start = rawStart;
        // define correct end
        if(rawEnd > $scope.pageCount)
        {
          var rest = rawEnd - $scope.pageCount;
          var end = rawEnd - rest;
        }
        else
        {
         var end = rawEnd;
        }

        // create final list of pages array
        for (var i = start; i <= end ; i++)
        {
          $scope.pageArray.push(i);
        }
        $scope.checkForShow = function(item)
        {
          var pos = attrs.buttons.indexOf(item);
          if(pos != -1)
            return true;
        }
        
        $scope.goToPage = function(event)
        {
          if(event.keyCode == 13)
          {
            var newPageNo = parseInt(event.target.textContent);

            if(newPageNo <= 0)
            {
              newPageNo = 1;
            }
            else if(newPageNo > $scope.pageCount)
            {
              newPageNo = $scope.pageCount;
            }

            event.preventDefault();
            var newUrl = $scope.url.replace('{pageNumber}', newPageNo);
            window.location.href = newUrl;
            return false;
          }
        };

        $scope.createNewUrl = function(item)
        {
          if(item == 0) item=1;
          if(item >= $scope.pageCount) item = $scope.pageCount;
          if(item == $scope.currentPage == 1)
          {
            return '';
          }
          else
          {
            var newUrl = $scope.url.replace('{pageNumber}', item);
            return newUrl;
          }
        }

      });
    };
    
    return{
      restrict: 'E',
      scope: {
        rowPerPage: "=",
        rowsCount: "=",
        currentPage: "=",
        range: "=",
        buttons: "@",
        url: "@urlPattern"
      },
      templateUrl: 'iShiaPagination.html',
      link: link
    }
  }
  ;
  
  init();
})(this.angular);
