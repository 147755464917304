/// <reference path="../../../../typings/angularjs/angular.d.ts" />

(function(ng)
{
  'use strict';
  var
  init = function()
  {
    ng
    .module('iShiaSortable', [])
    .directive('ishiaSortable', [directiveProvider])
  },

  arrayExchange = function(arrayObject, hostIndex, guestIndex)
  {
    var temp = arrayObject[hostIndex];
    arrayObject[hostIndex] = arrayObject[guestIndex];
    arrayObject[guestIndex] = temp;
    return arrayObject;
  },

  findIndex = function (node)
  {
    var i=1;
    while(node.previousSibling){
        node = node.previousSibling;
        if(node.nodeType === 1){
            i++;
        }
    }
    return i-1;
  },

  directiveProvider = function()
  {
    var
    link = function(scope, element, attr)
   {
     element.attr("draggable", "true");
     element.bind("dragstart", function(event)
     {
       event = event.originalEvent || event;

       this.classList.add('iShiaSortable-dragging');
        angular.element(this).addClass('iShiaSortable-dragging');

       var elmIndex = findIndex(element[0]);
       event.dataTransfer.setData("elmIndex", elmIndex);
     });

     element.bind("dragover", function(event)
     {
       event = event.originalEvent || event;
       
       event.preventDefault();
     });

     element.bind("drop", function(event)
     {
       event = event.originalEvent || event;

       event.preventDefault();
       // get guest index
       var guestIndex = event.dataTransfer.getData("elmIndex");
       var hostIndex = findIndex(element[0]);
       // switch childs
       scope.$parent.list = arrayExchange(scope.list, hostIndex, guestIndex);
       scope.$apply();
     });

     element.bind("dragend", function(event)
     {
       event = event.originalEvent || event;

       this.classList.remove('iShiaSortable-dragging');
     });
   }
    return {
      restrict: "A",
      scope:{
        list: "=ishiaSortable"
      },
      link:link
    }
  }
  ;
  init();
})(this.angular);
