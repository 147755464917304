/// <reference path="../../../../typings/angularjs/angular.d.ts" />

/**
 * L17r
 * L17r directive for AngularJS
 * By iShia Developer
 */

(function (root, ng)
{
  'use strict';

  var
  init = function ()
  {
    ng
    .module('L17rDirective', [])
    .directive('l17r', [directive])
    ;
  },

  /**
   * [directive description]
   * @return {[type]} [description]
   */
  directive = function ()
  {
    var

    link = function (scope, element, attrs)
    {
      var elementHtml = element[0].innerHTML;

      // Localize Text
      elementHtml = root.L17r(elementHtml);

      // Replace Orginal Element
      element[0].innerHTML = elementHtml;
    };

    return {
      restrict: 'EA',
      link: link
    };
  }
  ;
  /**
   * start point of program
   */
  init();
})(this, this.angular);