/// <reference path="../../../../typings/angularjs/angular.d.ts" />
/// <reference path="../../Managers/HttpManager.js" />

/**
 * iShiaUploadFile
 * UploadFile directive for AngularJS
 * By iShia Developer
 */

(function (root, ng)
{
  'use strict';
  var
  init = function ()
  {
    ng.module('iShiaUploadFile', ['iShiaLibrary.Managers.HttpManager'])
    .directive('ishiaUploadFile', ['$timeout', 'HttpManager', directive])
    ;
  },

  /**
   * [directive description]
   * @return {[type]} [description]
   */
  directive = function ($timeout, HttpManager)
  {
    var
      link = function ($scope, elem, attrs)
      {
        $scope.id = Math.floor((Math.random()*1000)+1);

        var start = function ()
        {
          var fileUpload = $('#file-upload-' + $scope.id);

          var fileName = fileUpload.find('#fileName'),
              progressLabel = fileUpload.find('.progress-label');
              
        // if($scope.uploaded == true && !ng.isUndefined($scope.fileName))
        // {
        //     fileName.text($scope.fileName);
        // }

          var r = new Resumable({
              target: '//upload.ishia.org/api/Upload/UploadFile?language=fa&cp=' + $scope.cp + '&upload_Token=' + getUniqueId(),
              query: {},
              testChunks: true,
              withCredentials: true
          });

          
          r.assignBrowse(fileUpload.find('.select-label'));
          r.assignBrowse(fileUpload.find('.faild-label'));

          fileUpload.find('.file-cancel-btn').click(function (e) {
              e.preventDefault();
              e.stopPropagation();
              fileUpload.find('.file-label').hide();              
              fileUpload.find('.select-label').show();              
              fileUpload.removeClass('send-ready').addClass('select-mode');
              r.cancel()
          });          

          fileUpload.find('.upload-label').click(function (e) {
              e.preventDefault();
              e.stopPropagation();
              fileUpload.find('.select-label').hide();              
              fileUpload.find('.upload-label').hide();              
              fileUpload.find('.uploading-label').show();
              fileUpload.removeClass('send-ready').addClass('sending-file');
              r.upload();
          });

         
          var playCtrl = fileUpload.find('.uploading-label').find('.play-ctrl'),
              playCtrlPause = fileUpload.find('.uploading-label').find('.play-ctrl__pause'),
              playCtrlPlay = fileUpload.find('.uploading-label').find('.play-ctrl__play');
            

          playCtrl.on('click', function(e){
            if(playCtrlPause.css( "display") === 'none') {
              playCtrlPause.show();
              playCtrlPlay.hide();
              fileUpload.removeClass('stop-upload');

              if (r.files.length > 0) {
                  if (!r.isUploading()) {
                      return r.upload();
                  }
                  return r.pause();
              }
            } 

            else {
              playCtrlPause.hide();
              playCtrlPlay.show();
              fileUpload.addClass('stop-upload');

              if (r.files.length > 0) {
                  if (r.isUploading()) {
                      return r.pause();
                  }
                  return r.upload();
              }

              e.stopPropagation();
            }
          });

          // play button end
          var progressBar = new ProgressBar(fileName);

          r.on('fileAdded', function (file, event) {
              fileUpload.removeClass('select-mode').addClass('send-ready');
              fileUpload.find('.file-label').hide(); 
              fileUpload.find('.upload-label').show();              
              progressBar.fileAdded();
              fileName.text(file.fileName);
              $('.file-size').text(Math.floor(((file.size/1024)/1024)) + ' Mb');

              file.resumableObj.opts.query.fileName = encodeURIComponent(file.fileName);
              file.resumableObj.opts.query.title = encodeURIComponent(file.fileName);
              angular.forEach(JSON.parse($scope.config), function(value, key)
              {
                  file.resumableObj.opts.query[key] = encodeURIComponent(value);
              });
          });

          r.on('fileSuccess', function (file, message) {
              fileUpload.find('.file-label').hide(); 
              fileUpload.find('.uploaded-label').show();              
              fileUpload.removeClass('sending-file').addClass('sent-file');
              progressBar.finish();

              $scope.uid = JSON.parse(message).UID;
              $scope.$apply();
          });

          r.on('fileError', function(file, message){
            fileUpload.find('.file-label').hide(); 
            fileUpload.find('.faild-label').show();              
            fileUpload.removeClass('sending-file').addClass('unsent-file');
          });

          r.on('progress', function () {
              progressBar.uploading(r.progress() * 100);
              progressLabel.text((Math.round(r.progress() * 100)) + '%');
          });


          function ProgressBar(ele) {
              this.thisEle = $(ele);

              this.fileAdded = function () {
                  (this.thisEle).css('background-position', '0% bottom');
                  progressLabel.hide();
                  fileUpload.find("#divWatermark").removeClass('hide');
              },

              this.uploading = function(progress) {
                progressLabel.show();
                (this.thisEle).css('background-position', progress+'% bottom');
                progressLabel.text(progress + '%');
              },

              this.finish = function () {
                  progressLabel.hide();
                  fileUpload.find("#divWatermark").addClass('hide');
              }
          }

          function getUniqueId()
          {
            var dateObject = new Date();
            var uniqueId =
                dateObject.getFullYear() + '' +
                dateObject.getMonth() + '' +
                dateObject.getDate() + '' +
                dateObject.getTime();

            return uniqueId;
            }
        };

        $timeout(start, 10);
        
      };

    return {
      restrict: 'E',
      scope: {
        cp: "@cp",
        mimes: "@mimes",
        uploaded: "=uploaded",
        uid: "=uid",
        config: "@config",
        type: "@"
      },
      templateUrl: 'iShiaUploadFile.html',
      link: link
    };
  }
  ;

  /**
   * start point of program
   */
  init();
})(this.window, this.angular);
