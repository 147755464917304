/// <reference path="../../../typings/angularjs/angular.d.ts" />

/**
 * L17r
 * L17r filter for AngularJS
 * By iShia Developer
 */

(function (root, ng)
{
  'use strict';

  var
  init = function ()
  {
    ng
    .module('L17rFilter', [])
    .filter('l17r', [filter])
    ;
  },

  /**
   * [filter description]
   * @return {[type]} [description]
   */
  filter = function ()
  {
    return function (input, lang)
    {
      var retVal = "";
      input = input || '';
      lang = lang || root.L17r.currentLang;
      
      // Localize Text
      retVal = root.L17r(input);
      
      return retVal;
    };
  }
  ;
  /**
   * start point of program
   */
  init();
})(this, this.angular);