/// <reference path="../../../../typings/angularjs/angular.d.ts" />
/// <reference path="../../../../typings/jquery/jquery.d.ts" />

/**
 * iShiaUploadImage
 * Upload Image directive for AngularJS
 * By iShia Developer
 */

; (function ()
{
  'use strict';

  var
    init = function ()
    {
      angular
        .module('iShiaUploadImage', ['iShiaCRs', 'iShiaAutocomplete', 'L17rFilter'])
        .directive('ishiaUploadImage', ['$rootScope', '$http', '$filter', directive])
        ;
    },

    /**
     * [directive description]
     * @return {[type]} [description]
     */
    directive = function ($rootScope, $http, $filter)
    {
      var
        link = function ($scope, elem, attrs)
        {
          $scope.disableSendButton = false;
          $scope.sendButtonText = 'UPLOAD';
          $scope.uploadContainer = false;

          // prepare uploadContainer
          $scope.data = $scope.data || {};
          $scope.showSaveButton = false;
          $scope.openUploadImageModal = function ()
          {
            $scope.modalMode = true;
            var defaultSize = $scope.defaultSize.split('*');
            var defaultWidth = parseInt(defaultSize[0]);
            var defaultHeight = parseInt(defaultSize[1]);

            $scope.submitType = 'send';
            $(".modal-box").toggleClass("modal-box--show");
            $(".back-drop.uploader-bg").toggleClass("active visible");

            // restore upload container
            $("#modaldialog").remove();
            // create image container dynamically
            var
              input = $("<input>").attr({
                type: 'file',
                name: 'thumb',
                style: 'position: absolute;'
              });

            var
              dropzone = $("<div>").attr({
                "class": 'dropzone html5imageupload smalltools smalltext',
                "id": "modaldialog",
                "dir": "ltr",
                "data-resize": "true",
                "data-width": defaultWidth,
                "data-height": defaultHeight,
                "data-ajax": "false",
                "data-originalsave": "true",
                "data-ghost": "true"
              }).css({
                width: defaultWidth,
                height: defaultHeight
              });
            dropzone.append(input);
            $("#dropzone").append(dropzone);
            $('#modaldialog').html5imageupload();
          }

          if ($scope.crMode)
          {
            console.info('crMode');
            $scope.uploadContainer = true;
            $scope.openUploadImageModal();
          }
          else
          {
            console.info('aadi');
          }

          $scope.isInArray = function (item)
          {
            if (!$scope.actions)
              $scope.actions = "['Add', 'Edit', 'Delete', 'DeleteFile']";
            var pos = $scope.actions.indexOf(item);
            if (pos != -1)
              return true;
          }

          $scope.showUploadModal = function ()
          {
            $scope.sendButtonText = 'UPLOAD';
            $scope.uploadContainer = true;
            $("#dropzone").find("#modaldialog").remove();
            if (typeof ($scope.defaultSize) == 'string')
              $scope.defaultSize = $scope.defaultSize.split('*');
            $scope.defaultWidth = parseInt($scope.defaultSize[0]);
            $scope.defaultHeight = parseInt($scope.defaultSize[1]);
            var imgTemp = new Image();
            imgTemp.src = $scope.imageSrc;
            var imgTempWidth = imgTemp.width;
            var imgTempHeight = imgTemp.height;

            var
              input = $("<input>").attr({
                type: 'file',
                name: 'thumb',
                style: 'position: absolute;'
              });

            var
              modaldialog = $("<div>").attr({
                "class": 'dropzone html5imageupload smalltools smalltext',
                "id": "modaldialog",
                "dir": "ltr",
                "data-resize": true,
                "data-width": $scope.defaultWidth,
                "data-height": $scope.defaultHeight,
                "data-ajax": false,
                "data-ghost": $scope.dataGhost,
                "data-image": $scope.imageSrc
              }).css({
                width: $scope.defaultWidth,
                height: $scope.defaultHeight
              });

            modaldialog.append(input);
            $("#dropzone").append(modaldialog);
            $('#modaldialog').html5imageupload();
          }

          if (!$scope.imageNo)
          {
            $scope.submitType = 'send';
          }
          else
          {
            $scope.getImageData($scope.imageNo);
            $scope.submitType = 'edit';
          }

          $scope.entityList = [
            {
              name: 'entry',
              entityno: 51,
              url: "//entities-api.ishia.org/AutoCompleteApi/getData?language=12&cp=-1&entity=51&search=",
              manadatory: true,
              currentRole: '',
              selectedobject: [],
              classes: "aaa bbb",
              dis: true,
              dataField: "data.entry"
            },
            {
              name: 'gallery',
              entityno: 74,
              url: "//entities-api.ishia.org/AutoCompleteApi/getData?language=12&cp=-1&entity=74&search=",
              manadatory: true,
              currentRole: '',
              selectedobject: [],
              classes: "aaa bbb",
              dis: true,
              dataField: "data.gallery"
            }
          ]
          $('#modaldialog').html5imageupload();

          // close modal
          $scope.closeImageModal = function ()
          {
            $scope.uploadContainer = false;
            $(".modal-box").removeClass('modal-box--show');
            $(".back-drop").removeClass('active visible');
          }

          $scope.setSize = function (event)
          {
            var sizeInString = event.target.innerHTML;
            var sizeInArray = sizeInString.split('*');
            var width = parseInt(sizeInArray[1]);
            var height = parseInt(sizeInArray[0]);
            $scope.defaultSize = width.toString() + '*' + height.toString();
            $("#modaldialog").remove();
            // create image container dynamically
            var
              input = $("<input>").attr({
                type: 'file',
                name: 'thumb',
                style: 'position: absolute;'
              });

            var
              dropzone = $("<div>").attr({
                "class": 'dropzone html5imageupload smalltools smalltext',
                "id": "modaldialog",
                "dir": "ltr",
                "data-resize": "true",
                "data-width": width,
                "data-height": height,
                "data-ajax": "false",
                "data-originalsave": "true"
              }).css({
                width: width,
                height: height
              });
            dropzone.append(input);
            $("#dropzone").append(dropzone);
            $('#modaldialog').html5imageupload();
          }

          window.setInterval(function ()
          {
            var canvas = document.getElementById("canvas_thumb");
            if (canvas != null)
              $scope.croppedImageIsOk = true;
            else
              $scope.croppedImageIsOk = false;

            // check send button
            if ($scope.lightMode)
            {
              $scope.disableSendButton = !$scope.croppedImageIsOk;
            }
            else
            {
              $scope.disableSendButton = !($scope.croppedImageIsOk && $scope.data.title);
            }

            // check save button
            $scope.disableSaveButton = !$scope.croppedImageIsOk;

            $scope.$apply();
          }, 500);

          $scope.saveImageDataInWindow = function ()
          {
            var canvas = document.getElementById("canvas_thumb");
            $scope.data = $scope.data || {};
            var obj = {};
            if (canvas)
            {
              obj.croppedImageData = canvas.toDataURL();
            }

            if ($scope.uploadOrginal)
            {
              obj.OrginalImageData = window.finalOriginalImageDataInIshiaImageUpload;
            }

            obj.imageTitle = $scope.data.title;
            obj.imageDescription = $scope.data.description;
            window.finalImageDataInIshiaImageUpload = obj;
            $scope.closeImageModal();
          }

          // change landscape to portrait and Conversely
          $scope.changeMode = function ()
          {
            var sizeInString = $scope.defaultSize;
            var sizeInArray = sizeInString.split('*');
            var width = parseInt(sizeInArray[1]);
            var height = parseInt(sizeInArray[0]);
            $scope.defaultSize = width.toString() + '*' + height.toString();
            console.info('width: ', width);
            console.info('height: ', height);
            console.info('defaultSize: ', $scope.defaultSize);
            $("#modaldialog").remove();
            // create image container dynamically
            var
              input = $("<input>").attr({
                type: 'file',
                name: 'thumb',
                style: 'position: absolute;'
              });

            var
              dropzone = $("<div>").attr({
                "class": 'dropzone html5imageupload smalltools smalltext',
                "id": "modaldialog",
                "dir": "ltr",
                "data-resize": "true",
                "data-width": width,
                "data-height": height,
                "data-ajax": "false",
                "data-originalsave": "true"
              }).css({
                width: width,
                height: height
              });
            dropzone.append(input);
            $("#dropzone").append(dropzone);
            $('#modaldialog').html5imageupload();
          }

          $scope.sendImageData = function ()
          {
            $scope.sendButtonText = 'UPLOADING';
            $('#imageSendButton').attr('disabled', true);
            var canvas = document.getElementById("canvas_thumb");
            $scope.data.CRs = $scope.SelectedCRs;
            var postData =
            {
              title: $scope.data.title,
              description: $scope.data.description,
              CpNo: $scope.cpno,
              SourceEntity: $scope.sourceEntity,
              ImageObject: JSON.stringify({ data: canvas.toDataURL() }),
              JustImage: false
            }

            if ($scope.lightMode)
            {
              postData.JustImage = true;
            }

            if ($scope.uploadOrginal)
            {
              postData.OriginalObject = window.finalOriginalImageDataInIshiaImageUpload;
            }

            $.ajaxSetup({
              crossDomain: true,
              xhrFields: {
                withCredentials: true
              }
            });

            $.ajax({
              type: "POST",
              contentType: "application/json",
              url: "//upload.api.ishiacloud.com/Image/addImage?language=" + window.L17r.currentLang,
              data: JSON.stringify(postData),
              xhrFields: {
                withCredentials: true
              }
            }).always(function (data)
            {
              if (data.Status == 1)
              {
                $scope.sendButtonText = 'UPLOAD_SUCCESS_MESSAGE';
                $scope.imageNo = data.Content.imageNo;
                window.setTimeout(function ()
                {
                  $(".modal-box").removeClass('modal-box--show');
                  $(".back-drop").removeClass('active visible');
                  $scope.resultMessage = '';
                  $scope.uploadContainer = false;
                  $scope.disableSendButton = true;

                  // 
                  $scope.imageValue = {};
                  $scope.imageValue.imageNo = data.Content.imageNo;
                  $scope.imageValue.imageName = data.Content.imageName;
                  $scope.imageValue.imageTitle = data.Content.imageTitle;
                  console.info('scope information: ', $scope);
                  console.info('sended information: ', $scope.imageValue);
                  $rootScope.$broadcast('sendImageData', $scope.imageValue);
                }, 3000);
              }
              else
              {
                $scope.sendButtonText = 'SEND';
                window.setTimeout(function ()
                {
                  $scope.resultMessage = 'خطا پیش آمد دوباره تلاش کنید';
                }, 2000);
                $scope.resultMessage = '';
              }
            });

            $scope.data.title = "";
            $scope.data.description = "";
          }
        };

      return {
        restrict: 'EA',
        scope: {
          "id": "@",
          "cpno": "@",
          "defaultSize": "@",
          "uploadOrginal": "=",
          "lightMode": "=",
          "saveButton": "=",
          "dataGhost": "=ghost",
          "crMode": "=",
          "sourceEntity": "="
        },
        templateUrl: 'iShiaUploadImage.html',
        link: link
      };
    }
    ;

  /**
   * start point of program
   */
  init();
})(this.angular, this.jQuery);
