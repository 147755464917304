/**
 * Localization language srings for persian/farsi language
 */

(function (root) 
{
  'use strict';
  
  var L17r = root.L17r || {langs: {}};
  if (!root.iShiaLibrary) console.error("iShiaLibrary Not Found!");

  L17r.langs.fa = root.iShiaLibrary.Managers.UtilityManager.mergeObjects(L17r.langs.fa, 
  {
    'LANG': 'fa',
    'LANGUAGE': 'فارسی',
    'SEARCH': 'جستجو',
    'SEARCHING': 'در حال جستجو',
    'NO_RESULT_FOUND': 'نتیجه ای یافت نشد'
  });
}
)(this);