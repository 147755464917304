/// <reference path="../../../../typings/angularjs/angular.d.ts" />
/// <reference path="../../Managers/HttpManager.js" />

/**
 * iShiaAutocomplete
 * Autocomplete directive for AngularJS
 * By iShia Developer
 */

(function (root, ng)
{
  'use strict';
  var
  init = function ()
  {
    ng.module('iShiaAutocomplete', ['iShiaLibrary.Managers.HttpManager', 'L17rFilter', 'dndLists'])
    .directive('ishiaAutocomplete', ['HttpManager', '$parse', '$sce', '$timeout', '$http', '$rootScope', directive])
    ;
  },

  /**
   * [directive description]
   * @return {[type]} [description]
   */
  directive = function (HttpManager, $parse, $sce, $timeout, $http, $rootScope)
  {
    var
      link = function ($scope, elem, attrs)
      {


        $scope.searchStr = 'milad';
        // $scope.$digest();
        $scope.createNewUrl = function(item, url)
        {   
          if(!ng.isUndefined(item) && !ng.isUndefined(item.no))
          {
            var newUrl = url.replace('{no}', item.no);
          }       
          return newUrl;
        }
        var counter = 0;
        $scope.$on('addTitleForImage', function(event, value) {
          if($scope.entityno == 73 && !$scope.multipleitems)
          {
            $scope.selectedObject = $scope.selectedObject.splice(0, 0);
            $scope.selectedObject.push(value);
            $scope.searchStr = value.title;
          }
        })

        $scope.$on('entryAdded', function(event, value) {
          if($scope.entityno == 51 && !$scope.multipleitems)
          {
            $scope.selectedObject = $scope.selectedObject.splice(0, 0);
            $scope.selectedObject.push(value);
            $scope.searchStr = value.title;
          }
          $scope.$apply();
        })
        $scope.$watch('selectedOption', function (newValue, oldValue)
        {
          var temp = {
            index : $scope.$parent.$index,
            value : newValue
          }
          $rootScope.$broadcast('optionAdded', temp);
        });

        $scope.checkIsArray = function(collection)
        {
          if(Array.isArray(eval('$scope.' + collection)))
            return true;
          else
            return false;
        }

        var entityNoToEntityName = function(no)
        {
          switch(no)
          {
            case 51:
              return 'Entries';
            case 122:
              return 'org';
            case 26:
              return 'books';
            case 73:
              return 'Images';
            case 24:
              return 'Clips';
            case 27:
              return 'Ebook';
            case 74:
              return 'Galleries';
            case 65:
              return 'News';
            case 11:
              return 'Quran';
            case 52:
              return 'Persons';
            case 59:
              return 'Fatwa';
            case 68:
              return 'Articles';
            case 33:
              return 'Website';
            case 22:
              return 'HistoricalEvents';
            case 54:
              return 'Places';      
          }
        }
        
        $scope.entityNoToEntityAction = function(entityno)
        {
          var retVal = '';
          switch(entityno)
          {
            default:
              retVal = 'Edit';              
          }
          return retVal;
        }
        
        // // update url string with dynamic data
        // if(!ng.isUndefined($scope.selectedEntitiesLink))
        // {
        //   $scope.selectedEntitiesLink = $scope.selectedEntitiesLink.replace('{entityNo}', entityNoToEntityName($scope.entityno));
        //   $scope.selectedEntitiesLink = $scope.selectedEntitiesLink.replace('{cp}', $scope.cp);
        //   $scope.selectedEntitiesLink = $scope.selectedEntitiesLink.replace('{action}', entityNoToEntityAction($scope.entityno));
        //   $scope.selectedEntitiesLink = $scope.selectedEntitiesLink.replace('{language}', window.L17r.currentLang);
        //   // $scope.selectedEntitiesLink = $scope.selectedEntitiesLink.replace('{no}', $scope.entityno);
        // }

        $scope.isInArray = function(field, collection)
        {
          var arr = eval('$scope.' + collection);
          if(jQuery.inArray(field, arr) !== -1)
            return true;
          else
            return false;
        }

        switch ($scope.entityno)
        {
          case 51:
            $scope.entityName = 'Entries';
            break;
          case 122:
            $scope.entityName = 'Orgs';
            break;
          case 26:
            $scope.entityName = 'Books';
            break;
          case 73:
            $scope.entityName = 'Pictures';
            break;
          case 24:
            $scope.entityName = 'Clips';
            break;
          case 74:
            $scope.entityName = 'Galleries';
            break;   
          case 65:
            $scope.entityName = 'News';
            break;
          case 11:
            $scope.entityName = 'Qurans';
            break;
          case 52:
            $scope.entityName = 'Persons';
            break;
          case 59:
            $scope.entityName = 'Fatwas';
            break;
          case 68:
            $scope.entityName = 'Articles';
            break;               
        }

        $scope.lastSearchTerm = null;
        $scope.currentIndex = null;
        $scope.justChanged = false;
        $scope.searchTimer = null;
        $scope.hideTimer = null;
        $scope.searching = false;
        $scope.pause = 500;
        $scope.minLength = 3;
        $scope.searchStr = null;
        $scope.multipleitems = $scope.multipleitems || false;
        $scope.options = $scope.options || [];
        $scope.firstSetData = false;

        if ($scope.multipleitems)
        {
          $scope.selectedObject = $scope.selectedObject || [];
        }
        else
        {
          $scope.selectedObject = $scope.selectedObject || [];
          if($scope.selectedObject.length > 0)
          {
            $scope.searchStr = $scope.selectedObject[0].title;
          }
        }

        $scope.selectedNo = $scope.selectedNo || -1;

        if ($scope.options.length > 0)
        {

        }

        if ($scope.minLengthUser && $scope.minLengthUser !== "")
        {
          $scope.minLength = $scope.minLengthUser;
        }

        if ($scope.userPause)
        {
          $scope.pause = $scope.userPause;
        }

        if ($scope.url && $scope.url !== "")
        {
          // $scope.url = "//" + $location.host() + $scope.url;
        }

        var isNewSearchNeeded = function (newTerm, oldTerm)
        {
          return newTerm.length >= $scope.minLength && newTerm != oldTerm
        };

        $scope.removeFromSelectedObject = function (item)
        {
          var indexItem = $scope.selectedObject.indexOf(item);
          if($scope.multipleitems)
          {
            $scope.selectedObject.splice(indexItem, 1);
          }
          else
          {
            // $scope.selectedObject = {};
            $scope.selectedObject = $scope.selectedObject.splice(0, 0);
          }
          $scope.searchStr = '';
        };

        $scope.changeNumberToChar = function(option, options)
        {
          var title;
          ng.forEach(options, function(item){
            if(item.no == option)            
              title = item.title;
          });          
          return title;
        }

        $scope.processResults = function (responseData, str)
        {
          if (responseData && responseData.length > 0)
          {
            $scope.results = [];

            var titleFields = [];
            if ($scope.titleField && $scope.titleField !== "")
            {
              titleFields = $scope.titleField.split(",");
            }

            for (var i = 0; i < responseData.length; i++)
            {
              // Get title variables
              var titleCode = [];

              for (var t = 0; t < titleFields.length; t++)
              {
                titleCode.push(responseData[i][titleFields[t]]);
              }

              var description = "";
              if ($scope.descriptionField)
              {
                description = responseData[i][$scope.descriptionField];
              }

              var imageUri = "";
              if ($scope.imageUri)
              {
                imageUri = $scope.imageUri;
              }

              var image = "";
              if ($scope.imageField)
              {
                image = imageUri + responseData[i][$scope.imageField];
              }

              var text = titleCode.join(' ');
              if ($scope.matchClass)
              {
                var re = new RegExp(str, 'i');
                var strPart = text.match(re)[0];
                text = $sce.trustAsHtml(text.replace(re, '<span class="' + $scope.matchClass + '">' + strPart + '</span>'));
              }

              var resultRow = {
                title: text,
                description: description,
                image: image,
                originalObject: responseData[i]
              }

              $scope.results[$scope.results.length] = resultRow;
            }

          }
          else
          {
            $scope.results = [];
          }
        }

        $scope.searchTimerComplete = function (str)
        {
          // Begin the search

          if (str.length >= $scope.minLength)
          {            
            if ($scope.localData)
            {
              var dataTarget = null;
              if($scope.dataField != null)
              {
                dataTarget = eval("$scope.localData" + "." + $scope.dataField);
                if (ng.isUndefined(dataTarget))
                {
                  dataTarget = [];
                }
              }
              else
              {
                dataTarget =  $scope.localData;
              }

              var searchFields = $scope.searchFields.split(",");

              var matches = [];

              for (var i = 0; i < dataTarget.length; i++)
              {
                var match = false;

                for (var s = 0; s < searchFields.length; s++)
                {
                  match = match || (typeof dataTarget[i][searchFields[s]] === 'string' && typeof str === 'string' && dataTarget[i][searchFields[s]].toLowerCase().indexOf(str.toLowerCase()) >= 0);
                }

                if (match)
                {
                  matches[matches.length] = dataTarget[i];
                }
              }

              $scope.searching = false;
              $scope.processResults(matches, str);
            }
            else
            {
              var url = $scope.url.replace("{SearchText}", ''); //+ '&searchToFields=' + $scope.searchToFields;
              $http
              .get(url, {withCredentials: true})
              .then(
                function success(response, status, headers, config)
                  {
                    var responseData = response.data;
                    $scope.searching = false;
                    if($scope.dataField)
                    {
                      responseData = eval("responseData" + "." + $scope.dataField);
                      if (ng.isUndefined(responseData))
                      {
                        responseData = [];
                      }
                    }

                    $scope.processResults(responseData, str);

                  },
                  function error(data, status, headers, config)
                  {
                    // console.log("error");
                  }
              )
              // HttpManager.getFromURL(
              //   $scope.url.replace("{SearchText}", str), { withCredentials: true })
              //   .then(
              //     function success(responseData, status, headers, config)
              //     {
              //       $scope.searching = false;
              //       if($scope.dataField)
              //       {
              //         responseData = eval("responseData" + "." + $scope.dataField);
              //         if (ng.isUndefined(responseData))
              //         {
              //           responseData = [];
              //         }
              //       }

              //       $scope.processResults(responseData, str);

              //     },
              //     function error(data, status, headers, config)
              //     {
              //       console.log("error");
              //     }
              //   );
            }
          }
        }

        $scope.hideResults = function ()
        {
          $scope.hideTimer = $timeout(function ()
          {
            $scope.showDropdown = false;
          }, $scope.pause);
        };

        $scope.resetHideResults = function ()
        {
          if ($scope.hideTimer)
          {
            $timeout.cancel($scope.hideTimer);
          }
        };

        $scope.hoverRow = function (index)
        {
          $scope.currentIndex = index;
        };

        $scope.keyPressed = function (event)
        {          
          if (!(event.which == 38 || event.which == 40 || event.which == 13))
          {
            if (!$scope.searchStr || $scope.searchStr === "")
            {
              $scope.showDropdown = false;
              $scope.lastSearchTerm = null
            }
            else if (isNewSearchNeeded($scope.searchStr, $scope.lastSearchTerm))
            {
              $scope.lastSearchTerm = $scope.searchStr
              $scope.showDropdown = true;
              $scope.currentIndex = -1;
              $scope.results = [];

              if ($scope.searchTimer)
              {
                $timeout.cancel($scope.searchTimer);
              }
              $scope.searching = true;
              // make URL customize : based on url fetched from CRs component
              $scope.url = $scope.baseUrl + $scope.lastSearchTerm;

              ///////////////////////////////////////
              $scope.searchTimer = $timeout(function ()
              {
                $scope.searchTimerComplete($scope.searchStr);
              }, $scope.pause);
            }
          }
          else
          {
            event.preventDefault();
          }
        };

        $scope.selectResult = function (result)
        {
          // console.log(result);
          result.no = result.originalObject.no;
          if(result.title == 'NO_AUTHORIZE')
            return;
          if ($scope.matchClass)
          {
            result.title = result.title.toString().replace(/(<([^>]+)>)/ig, '');
          }
          if($scope.options.length > 0)
          {
            result.originalObject.option = $scope.selectedOption;   
          }
          if ($scope.multipleitems)
          {
            $scope.searchStr = $scope.lastSearchTerm = '';
            $scope.selectedObject.push(result);
          }
          else
          {
            // $scope.searchStr = $scope.lastSearchTerm = result.title;
            $scope.searchStr = result.title;
            $scope.selectedObject = $scope.selectedObject.splice(0, 0);
            $scope.selectedObject.push(result);
            // $scope.selectedObject[0] = result;
            if($scope.selectedNo != -1)
            {
              $scope.selectedNo = result.originalObject.no;
            }
          }
          $scope.showDropdown = false;
          $scope.results = [];
        };

        var inputField = elem.find('input');

        inputField.on('keydown', function(event){
          if (event.which  === 9)
            event.preventDefault();
        });

        inputField.on('keyup', $scope.keyPressed);
        elem.on("keyup", function (event)
        {          
          if (event.which === 40)
          {            
            if ($scope.results && ($scope.currentIndex + 1) < $scope.results.length)
            {
              $scope.currentIndex++;
              $scope.$apply();
              event.preventDefault;
              event.stopPropagation();
            }
            $scope.$apply();
          }
          else if (event.which == 38)
          {           
            if ($scope.currentIndex >= 1)
            {
              $scope.currentIndex--;
              $scope.$apply();
              event.preventDefault;
              event.stopPropagation();
            }
          }

          else if (event.which == 13 || event.which == 9)
          {
            
            if ($scope.results && $scope.currentIndex >= 0 && $scope.currentIndex < $scope.results.length)
            {
              $scope.selectResult($scope.results[$scope.currentIndex]);
              $scope.$apply();
              event.preventDefault;
              event.stopPropagation();
            }
            else
            {
              $scope.results = [];
              $scope.$apply();
              event.preventDefault;
              event.stopPropagation();
            }
          }
          else if (event.which == 27)
          {
            $scope.results = [];
            $scope.showDropdown = false;
            $scope.$apply();
          }
          else if (event.which == 8)
          {
            if (!$scope.multipleitems)
            {
              $scope.selectedObject = $scope.selectedObject.splice(0, 0);
              $scope.$apply();
            }
          }
        });


        //if ($scope.multipleitems)
        //{
        //  $scope.processResults($scope.selectedObject, '');
        //}
        //else
        //{
        //  $scope.processResults([$scope.selectedObject], '');
        //}

        var clearSelectedObjectWatch = $scope.$watch('selectedObject', function (newValue, oldValue)
        {
          //$scope.processResults([$scope.selectedObject], '');
          if ($scope.selectedObject && $scope.selectedObject.no)
          {
            if (!$scope.lastSearchTerm)
            {
              clearSelectedObjectWatch();
              $scope.searchStr = $scope.lastSearchTerm = $scope.selectedObject.title;
              $scope.selectedObject.originalObject = ng.copy($scope.selectedObject);
            }
          }
        });
        
        $scope.getOptionTitle = function(no)
        {
          var retVal = '';
          ng.forEach($scope.options, function(item)
          {
            if(item.no == no)
            {
              retVal = root.L17r(item.title);
              return;
            }
          });
          return retVal;
        };
      };

    return {
      restrict: 'EA',
      scope: {
        "id": "@id",
        "multipleitems": "=multipleitems",
        "placeholder": "@placeholder",
        "selectedObject": "=selectedobject",
        "selectedNo": "=selectedno",
        "url": "@url",
        "baseUrl": "@url",
        "dataField": "@datafield",
        "titleField": "@titlefield",
        "descriptionField": "@descriptionfield",
        "imageField": "@imagefield",
        "imageUri": "@imageuri",
        "inputClass": "@inputclass",
        "userPause": "@pause",
        "localData": "=localdata",
        "searchFields": "@searchfields",
        "minLengthUser": "@minlength",
        "matchClass": "@matchclass",
        "dis": "=",
        "options": "=options",
        "entityno":"=entityno",
        "quickadd":"=quickadd",
        "cpNo":"@cpno",
        "action":"@action",
        "searchToFields":"@searchtofields",
        "displayfields":"=displayfields",
        "selectedEntitiesLink":"=selectedentitieslink",
        "quickaddlightform":"=quickaddlightform"
      },
      templateUrl: 'iShiaAutocomplete.html',
      link: link
    };
  }
  ;

  /**
   * start point of program
   */
  init();
})(this.window, this.angular);
