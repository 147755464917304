(function (ng)
{
  'use strict';

  var
    init = function ()
    {
        ng
        .module('iShiaLibrary.Managers.HttpManager', [])
        .factory('HttpManager', ['$http', '$q', factoryProvider])
    },

    factoryProvider = function ($http, $q)
    {
      return {
        getFromURL: function(url, options)
        {
          options = options || {};
          return getFromURL(url, options, $http, $q);
        },
        postToURL: function (url, data)
        {
          return postToURL(url, data, $http, $q);
        }
      }
    },

    getFromURL = function (url, options, $http, $q)
    {
      var deferred = $q.defer();

      options.header = options.header || {};
      options.header['Content-Type'] = 'application/json; charset=UTF-8';

      $http.get(url, options).then(
        function success(response, status, headers, config)
        {
          deferred.resolve(response.data, response, status, headers, config);
        },
        function error(response)
        {
          deferred.reject(response);
        }
      );

      return deferred.promise;;
    },
      
    postToURL = function (url, data, $http, $q)
    {
      var deferred = $q.defer();

      $http.post(url, data).then(
        function success(response, status, headers, config)
        {
          deferred.resolve(response.data, response, status, headers, config);
        },
        function error(response)
        {
          deferred.reject(response);
        }
      );

      return deferred.promise;
    }

  ;
  init();
})(this.angular);