/// <reference path="../../../../typings/angularjs/angular.d.ts" />
/// <reference path="../../../JS/Managers/EntityManager.js" />

(function(root, ng)
{
  'use strict';
  var
  init = function()
  {
    ng
    .module('iShiaCRs', ['L17rFilter', 'iShiaAutocomplete', 'iShiaUploadImage'])
    .directive('ishiaCrs', ['$compile', '$http', '$rootScope', directive])
  },

  directive = function($compile, $http, $rootScope)
  {
    var link = function($scope, element, attrs, ctrl)
    {
      $scope.disableBtn = [];
      var
      cp = $scope.cp,
      showEffect = function(entityno)
      {
        $("#" + entityno).addClass('active');
        setTimeout(function()
        {
          $("#" + entityno).removeClass('active');
        }, 1000);
      };

      $scope.$on('optionAdded', function(event, value) {
        $scope.disableBtn[value.index] = value.value;
      })

      $scope.$on('sendImageData', function(event, value) {
        var tempObject = {
          no: value.imageNo,
          title : value.imageTitle,
          imageName: value.imageName,
          cpNo: $scope.cp
        }
        $scope.selectedEntites[$scope.entityIndex].selectedobject.push(tempObject);
        // $rootScope.$broadcast('addTitleForImage', tempObject);
      })

      $scope.currentLangCode = root.L17r.currentLangCode;
      $scope.entityNoToEntityName = function(no)
      {
        var tempName;
        ng.forEach($scope.entityList, function(val)
        {
          if(no == val.entityno)
          {
            tempName = root.iShiaLibrary.Managers.EntityManager.entityNoToName(no);
          }
        });
        return tempName;
      } 

      $scope.saveEntry = function()
      {
        
        $("#entriesBtn").html('در حال پردازش، لطفا شکیبا باشید...');
        $("#entriesBtn").attr("disabled", "disabled");
        var model = 
        {
          CpNo : cp,
          Title : $scope.entry.subject
        }
        if(model.Title)
        {
          $.post("//entities-api.ishia.org/EntriesApi/quickAdd?language=" + window.L17r.currentLangCode + "&entity=51",
            model,
            function(data, status)
            {
              
              if(data.status)
              {
                $scope.entry.subject = '';
                $("#entriesBtn").html('ثبت');
                $("#entriesBtn").removeAttr("disabled");
                $(".back-drop").removeClass('active visible');            
                $(".modal-box").removeClass('modal-box--show');
                
                var temp = {};
               
                temp = {};
                temp.no = data.data[0].no;
                temp.title = data.data[0].title;
                temp.cpNo = data.data[0].cpNo;
                $scope.selectedEntites[$scope.entityIndex].selectedobject.push(temp);

                $scope.$apply();
                $rootScope.$broadcast('entryAdded', temp);
              }
              else
              {
                $scope.errorMessage = "خطایی رخ داده است مجددا امتحان کنید";              
              }
            }
          );
        }
        else
        {
          // console.info('empty');
        }
      }

      $scope.registerUser = function()
      {
        // console.info($scope.disableBtn);
        // console.info($scope);
        $("#personBtn").html('در حال پردازش، لطفا شکیبا باشید...');
        $("#personBtn").attr("disabled","disabled");
        var model = 
          {
            firstName : $scope.user.firstName,
            lastName : $scope.user.lastName,
            cpNo :cp,
            titleNo : -1,
            konya : $scope.user.konya,
            gender : 1
          }
          $.post("//entities-api.ishia.org/PersonApi/quickAdd?language=" + window.L17r.currentLangCode+"&entity=52",
            model,
            function(data, status)
            {
              $("#personBtn").html('ثبت');
              $("#personBtn").removeAttr("disabled");
              if(data.status)
              {
                $(".back-drop").removeClass('active visible');
                $(".modal-box").removeClass('modal-box--show');
                var temp = {};
                temp.no = data.data.no;
                temp.title = data.data.title;
                temp.option;
                temp.originalObject = temp.originalObject || {};
                temp.originalObject.option = $scope.disableBtn[$scope.entityIndex];
                $scope.selectedEntites[$scope.entityIndex].selectedobject.push(temp);
                $scope.$apply();
              }
              else
              {
                $scope.errorMessage = "خطایی رخ داده است مجددا امتحان کنید";
              }
              $scope.user = {};
            }
          );
      }

      $scope.closeModal = function()
      {
        $(".back-drop").removeClass('active visible');
        $(".modal-box").removeClass('modal-box--show');
      }

      $scope.openQuickAdd = function(name, entityNo, mode, index)
      {
        $scope.disableEntriesBtn = true;
        $scope.entry = {};
        $scope.entry.subject = '';
        $scope.entityIndex = index;
        $scope.CRtype = name;
        $(".back-drop").addClass('active visible');
        $(".modal-box").addClass('modal-box--show');
        switch (entityNo)
        {
          case 51:           
            $scope.templatePath = '@@include("Templates/qa-entry.html")';
            break;
          case 52:
            $scope.templatePath = '@@include("Templates/qa-person.html")';
            break;
          case 73:
          
            $scope.b = mode;
            $scope.templatePath = '@@include("Templates/qa-image.html")';
            // create config object to apply on image uploader template
            $scope.tempConfigObject = new Object();
            $scope.tempConfigObject.uploadOrginal = JSON.parse(this.entityItem.uploadOrginal);
            $scope.tempConfigObject.defaultSize = this.entityItem.defaultSize;
            
            $scope.tempConfigObject.lightMode = JSON.parse(this.entityItem.lightMode);
            $scope.tempConfigObject.crMode = true;
            $scope.tempConfigObject.ghost = JSON.parse(this.entityItem.ghost);
            $scope.tempConfigObject.CpNo = cp;
            if(!ng.isUndefined(this.entityItem.sourceEntity))
            {
              $scope.tempConfigObject.sourceEntity =  JSON.parse(this.entityItem.sourceEntity);
            }else
            {
              $scope.tempConfigObject.sourceEntity = 0;
            }
            break;  
        }        
        // console.info($compile($scope.templatePath)($scope));
        $("#containere").html($compile($scope.templatePath)($scope));
        if(entityNo == 73)
        {
          // console.info($compile($scope.templatePath)($scope));
          $("#containere").html($compile($scope.templatePath)($scope));
          
          // set default Size
          var sizeInString = $scope.tempConfigObject.defaultSize;
          var sizeInArray = sizeInString.split('*');
          var width = parseInt(sizeInArray[0]);
          var height = parseInt(sizeInArray[1]);
          $("#modaldialog").remove();
          // create image container dynamically
          var
          input = $("<input>").attr({
            type:'file',
            name: 'thumb',
            style: 'position: absolute;'
          });
          
          var
          dropzone = $("<div>").attr({
            "class": 'dropzone html5imageupload smalltools smalltext',
            "id": "modaldialog",
            "dir": "ltr",
            "data-resize": "true",
            "data-width": width,
            "data-height": height,
            "data-ajax": "false",
            "data-originalsave": "true"
          }).css({
            width: width,
            height: height
          });
          dropzone.append(input);
          $("#dropzone").append(dropzone);
          $('#modaldialog').html5imageupload();
        }
        
      }

      $scope.setSize = function(event)
      {
        var sizeInString = event.target.innerHTML;
        var sizeInArray = sizeInString.split('*');
        var width = parseInt(sizeInArray[1]);
        var height = parseInt(sizeInArray[0]);
        $("#modaldialog").remove();

        // create image container dynamically
        var
        input = $("<input>").attr({
          type:'file',
          name: 'thumb',
          style: 'position: absolute;'
        });
        
        var
        dropzone = $("<div>").attr({
          "class": 'dropzone html5imageupload smalltools smalltext',
          "id": "modaldialog",
          "dir": "ltr",
          "data-resize": "true",
          "data-width": width,
          "data-height": height,
          "data-ajax": "false",
          "data-originalsave": "true"
        }).css({
          width: width,
          height: height
        });

        dropzone.append(input);
        $("#dropzone").append(dropzone);
        $('#modaldialog').html5imageupload()

      }
      
      $scope.removeEntity = function(itemID)
      {
        ng.forEach($scope.entityList, function(item){
          if(item.id == itemID)
            item.visible = false;
        });
        $scope.rearrangeList2();
      };

      $scope.selectedEntites = $scope.selectedEntites || [];

      $scope.updateModel = function(item)
      {
        ctrl.$setViewValue(item);
      };

      ctrl.$viewChangeListeners.push(function()
      {
        $scope.$eval(attrs.ngChange);
      });

      ctrl.$render = function()
      {
        $scope.selectedObject = ctrl.$modelValue || [];
      };

      $scope.$watch('selectedEntites', function (newValue, oldValue)
      {
        if ($scope.selectedEntites)
        {
          $scope.selectedObject = ng.copy($scope.selectedEntites);
          ng.forEach($scope.selectedObject, function(entityItem)
          {
            if (ng.isArray(entityItem.selectedobject))
            {
              for (var i = 0; i < entityItem.selectedobject.length; i++)
              {
                var selectedObject = entityItem.selectedobject[i];              
                if(selectedObject.originalObject)
                {
                  entityItem.selectedobject[i] = selectedObject.originalObject;
                }
              }
            }
            else if (ng.isObject(entityItem.selectedobject))
            {
              if(entityItem.selectedobject.originalObject)
              {
                entityItem.selectedobject = entityItem.selectedobject.originalObject;
              }
            }
          });
          $scope.updateModel($scope.selectedObject);
          $scope.rearrangeList2();
        }      
      }, true);

      $scope.rearrangeList2 = function()
      {
        $scope.optionalList = [];
        $scope.selectedEntites = [];
        var id = 1;
        ng.forEach($scope.entityList, function(entity)
        {
          entity.id = id++;
          if(entity.manadatory || entity.visible)
          {
            $scope.selectedEntites.push(entity);
          }
          else
          {
            $scope.optionalList.push(entity);
          }
        });        
      }

      $scope.rearrangeList = function()
      {       
        $scope.optionalList = [];
        var id = 1;
        ng.forEach($scope.entityList, function(entity)
        {
          if(entity.selectedobject.length > 0 )
            {
              entity.visible = true;
            }
          entity.id = id++;
          if(entity.manadatory || entity.visible)
          {
            $scope.selectedEntites.push(entity);
          }
          else
          {
            $scope.optionalList.push(entity);
          }
        });
      }

      $scope.rearrangeList();

      $scope.addEntity = function(itemID)
      {
        for (var i = 0; i < $scope.entityList.length; i++)
        {
          var entityItem = $scope.entityList[i];
          if (entityItem.id == itemID)
          {
            entityItem.visible = true;
            var indexInOptionalList = $scope.optionalList.indexOf(entityItem);
            $scope.optionalList.splice(indexInOptionalList, 1);
            $scope.selectedEntites.push(entityItem);
            $scope.optionalSelection = root.L17r('OTHER_ENTITY');
          }
        }

        setTimeout(function()
        {
          showEffect($scope.entityList[itemID-1].entityno);
        }, 100);
      }

    }
    return{
      restrict: 'E',
      templateUrl: 'Templates/iShiaCRs.html',
      require: 'ngModel',
      scope:
      {
        entityList: "=entitylist",
        url:"@url",
        cp:"="
      },
      link: link
    }
  }
  ;
  init();

})(this, this.angular);
