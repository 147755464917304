/*
 * UtilityManager v1.0
 */

(function (root) 
	{
  'use strict';

  var
  
  /**
   * Initialize
   */
  init = function () 
  {
    if(!root.iShiaLibrary) root.iShiaLibrary = {};
    if(!root.iShiaLibrary.Managers) root.iShiaLibrary.Managers = {};
    if(!root.iShiaLibrary.Managers.UtilityManager) root.iShiaLibrary.Managers.UtilityManager = {};

    root.iShiaLibrary.Managers.UtilityManager.mergeObjects = mergeObjects;
    root.iShiaLibrary.Managers.UtilityManager.addQueryStringToURL = addQueryStringToURL;
  },

  /**
  * Overwrites obj1's values with obj2's and adds obj2's if non existent in obj1
  * @param obj1
  * @param obj2
  * @returns obj3 a new object based on obj1 and obj2
  */
  mergeObjects = function (obj1, obj2)
  {
    var retVal = {};

    for (var attrname in obj1) 
    { 
      retVal[attrname] = obj1[attrname]; 
    }
    for (var attrname in obj2) 
    { 
      retVal[attrname] = obj2[attrname]; 
    }
    
    return retVal;
  },
  
  
  addQueryStringToURL = function (url, queryName, value)
  {
    if (url != null && url != "")
    {
        var newURL = "";
        var queryString = "";

        var myregexp = /([#]|^)([^#?]+)\/([^#]*)$/m;
        var match = myregexp.exec(url);
        if (match != null)
        {
            newURL = match[2];
            queryString = match[3];
        }

        if (queryName != "" && queryName != null && (value + "") != "" && value != null)
        {
            var newURL = "";
            var queryString = "";

            var myregexp = /([#]|^)([^#?]+)\/([^#]*)$/m;
            var match = myregexp.exec(url);
            if (match != null)
            {
                newURL = match[2];
                queryString = match[3];
            }

            if (queryString == "")
            {
                queryString = queryName + "=" + value;
            }
            else
            {
                if (queryString.indexOf(queryName + "=") != -1)
                {
                    var regexString = new RegExp("(^|&)(" + queryName + ")=([^&]+)?", "mg");
                    queryString = queryString.replace(regexString, "$1$2=" + value);
                }
                else
                {
                    queryString += "&" + queryName + "=" + value;
                }
            }
        }

        if (queryString != "")
        {
            newURL += "/" + queryString;
        }
        return newURL;
    }
    else
    {
      return url;
    }
  }
  
  ;
  init();
})(this);