/// <reference path="../../../../typings/angularjs/angular.d.ts" />

/**
 * iShiaSearch
 * Search directive for AngularJS
 * By iShia Developer
 */

(function (ng, root)
{
  'use strict';

  var
  init = function ()
  {
    ng
    .module('iShiaSearch', ['iShiaAutocomplete', 'L17rFilter', 'ishiadatepicker', 'ui.router'])
    .directive('ishiaSearch', ['$http', directive])
    ;
  },

  directive = function ($http, $location)
  {
    var
      link = function ($scope, $element, $attrs)
      {
        $scope.model = {};
        $scope.search = function()
        {
          var tempContainer = [];
          tempContainer.push($scope.model);
          console.table(tempContainer);
        }

        $scope.countries = {
          data1:{
            data: [
                {name: 'Afghanistan', no: '1'},
                {name: 'Aland Islands', no: '2'},
                {name: 'Albania', no: '3'}
            ]
          }
        }
      };

    return {
      restrict: 'E',
      templateUrl: 'iShiaSearch.html',
      scope:{
        configObject: '=config'
      },
      link: link
    }
  }
  ;

  /**
   * start point of program
   */
  init();
})(this.angular, this);