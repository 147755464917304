/// <reference path="../../../../typings/angularjs/angular.d.ts" />
;(function(ng)
{
  'use strict';
  
  // update behaviors
  String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };

  var
  parsiDigits = ['۰', '۱', '۲', '۳', '۴', '۵', '۶', '۷', '۸', '۹'],
  currectCentury,
  j = 0,
  result = 0,
  i = 0,
  tempM,
  currentDateArray,
  m,
  t,
  row,
  coloumn,
  btn,
  dateString,
  remainingNumbers = [1, 5, 9, 13, 17, 22, 26, 30],
  englishDigits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '۹'],
  englishDigit,
  parsiDigit,
  init = function()
  {
    ng
    .module('ishiadatepicker', [])
    .filter('convertToParsiDigitFilter', function() {      
      return function(x, scope) {
        if(scope.calendarType == 'gregorian')
        {
          parsiDigit = x;
        }
        else
        {
          var digits = x.toString().split('');
          var parsiDigit = '';
          for(var i = 0;i < digits.length; i++)
          {
            parsiDigit += parsiDigits[digits[i]];
          }
        }
        return parsiDigit;
      }
    })
    .directive('ishiaDatepicker', ['$document', '$templateCache', '$templateRequest', '$compile', directiveProvider])
  },

  directiveProvider = function($document, $templateCache, $templateRequest, $compile)
  {
    var
    link = function($scope, $element, $attrs)
    {
      var
        calendarsList = $attrs.ishiaDatepicker.split(','),
        calendarType = calendarsList[0].trim();
      $scope.calendarType = calendarType;
      $(document).mouseup(function (e)
      {
        var container = $(".iShia-datepicker");
        if (!container.is(e.target)
          && container.has(e.target).length === 0)
        {
          container.hide();
        }
      });

      //  create current date string
      $scope.closeCalendar = function()
      {
        if(document.getElementById($scope.parentID))
          document.getElementById($scope.parentID).remove();
      };

      var
      firstInitialize = function()
      {
        switch(calendarType)
        {
          case 'jalaali':
            $scope.currentCentury = 14;  // default value for jalaali century
            $scope.weekDay = ['شنبه', 'یکشنبه', 'دوشنبه', 'سه شنبه', 'چهارشنبه', 'پنجشنبه', 'جمعه'];
            $scope.MonthArray = ['فروردین', 'اردیبهشت' , 'خرداد', 'تیر', 'مرداد', 'شهریور', 'مهر', 'آبان', 'آذر', 'دی', 'بهمن', 'اسفند'];
            tempM = moment().format('jYYYY/jM/jD/jdddd');
            break;
          case 'gregorian':
            $scope.currentCentury = 21;  // default value for gregorian century
            $scope.weekDay = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
            $scope.MonthArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
            tempM = moment().format('YYYY/M/D/dddd');
            break;  
        }
             
        currentDateArray = tempM.split('/');
        dateString = currentDateArray[0] + '/' + currentDateArray[1] + '/1';
        if (calendarType == 'jalaali')
          m = moment(dateString, 'jYYYY/jM/jD/jdddd');
        else
          m = moment(dateString, 'YYYY/M/D/dddd');
        if (calendarType == 'jalaali')
          $scope.currentYear = m.jYear();
        else
          $scope.currentYear = m.year();
        //
        if (calendarType == 'jalaali')
          var x = moment(dateString, 'jYYYY/jM/jD');
        else
          var x = moment(dateString, 'YYYY/M/D');
        var y = x.format('dddd');        
        //
        if (calendarType == 'jalaali')
          $scope.currentMonthIndex = m.jMonth();
        else
          $scope.currentMonthIndex = m.month();
        if (calendarType == 'jalaali')
          $scope.numberOfDaysInMonth = daysInMonth($scope.currentMonthIndex);
        else
          $scope.numberOfDaysInMonth = m.daysInMonth($scope.currentMonthIndex);
        dateString = $scope.currentYear + '/' + $scope.currentMonthIndex + '/' + '1';
        console.info('currentMonthIndex: ', $scope.currentMonthIndex);
        console.info('monthFirstDayOfWeek: ',monthFirstDayOfWeek(y));
        console.info('numberOfDaysInMonth: ',$scope.numberOfDaysInMonth);
        console.info('numberOfWeeksInMonth: ',numberOfWeeksInMonth(monthFirstDayOfWeek(y)));
        $scope.monthFirstDayOfWeek = monthFirstDayOfWeek(y);
        // current date info
        $scope.currentYearIndex1 = currentDateArray[0];
        $scope.currentMonthIndex1 = $scope.currentMonthIndex;
        $scope.currentDayIndex1 = currentDateArray[2];
      },
      
      convertToParsiDigit = function(EnglishNumber)
      {
        var digits = EnglishNumber.toString().split('');
        parsiDigit = '';
        for(var i = 0;i < digits.length; i++)
        {
          parsiDigit += parsiDigits[digits[i]];
        }
        return parsiDigit;
      },

      makeMap = function(parsiDigit)
      {
        switch (parsiDigit) {
          case '۰':
            return 0;
          case '۱':
            return 1;
          case '۲':
            return 2;
          case '۳':
            return 3;
          case '۴':
            return 4;
          case '۵':
            return 5;
          case '۶':
            return 6;
          case '۷':
            return 7;
          case '۸':
            return 8;
          case '۹':
            return 9;
        }
      },

      convertToEnglishDigit = function(parsiNumber)
      {
        var digits = parsiNumber.toString().split('');
        englishDigit = '';
        for(var i = 0;i < digits.length; i++)
        {
          englishDigit += makeMap(digits[i]);
        }
        return englishDigit;
      },
      
      monthFirstDayOfWeek = function(dayname)
      {
        switch (dayname.toLowerCase())
        {
          case 'saturday':
            return 0;
            break;
          case 'sunday':
            return 1;
            break;
          case 'monday':
            return 2;
            break;
          case 'tuesday':
            return 3;
            break;
          case 'wednesday':
            return 4;
            break;
          case 'thursday':
            return 5;
            break;
          case 'friday':
            return 6;
            break;
        }
      },

      numberOfWeeksInMonth = function(startDay)
      {
        var retVal;
        switch (startDay)
        {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
            retVal = 5;
            break;
          case 6:
            retVal = 6;
            break;
        }

        if ($scope.numberOfDaysInMonth == 31 && startDay == 5)
        {
          retVal = 6;
        }

        return retVal;
      },

      render4HTML = function()
      {        
        var k =0;
        var j =0;

        var dynamicPart = document.createElement("table");
        if($attrs.ishiaDatepicker == 'jalaali')
        {
          dynamicPart.style.direction = "rtl";
        }
        else
        {
          dynamicPart.style.direction = "ltr";
        }
        dynamicPart.setAttribute("class", "datepicker-days-wrap");
        dynamicPart.setAttribute("id", $scope.parentID + "dynamicPart");

        var dynamicPartTHead = document.createElement("thead");
        var dynamicPartTHeadTr = document.createElement("tr");
        dynamicPartTHead.appendChild(dynamicPartTHeadTr);
        dynamicPart.appendChild(dynamicPartTHead);
        for(var i=0;i<7;i++)
        {
          var th = document.createElement('th');
          th.innerHTML = $scope.weekDay[i];
          dynamicPartTHeadTr.appendChild(th);
        }

        var dynamicPartTBody = document.createElement("tbody");
        dynamicPart.appendChild(dynamicPartTBody);

        for(var i = 0 ; i < numberOfWeeksInMonth($scope.monthFirstDayOfWeek) ; i++)
        {
          var tr = document.createElement('tr');
          for(var j = 0 ; j < 7 ; )
          {
            var td = document.createElement('td');
            // create button for each day
            if(i == 0 && j >= $scope.monthFirstDayOfWeek)
              k++;
            if(i > 0)
              k++;
            if(k > 0 && k <= $scope.numberOfDaysInMonth)
            {
              if(calendarType == 'jalaali')
              {
                td.innerHTML = convertToParsiDigit(k);
              }
              else
              {
                td.innerHTML = k;
              }

              // define correct century
              if(calendarType == 'jalaali')
              {
                currectCentury = '14';
              }
              if(calendarType == 'gregorian')
              {
                currectCentury = '21';
              }
              console.info('currectCentury: ', currectCentury);
              if (k == currentDateArray[2] && (currentDateArray[1] == ($scope.currentMonthIndex + 1)) && currentDateArray[0] == $scope.currentYear && currectCentury == $scope.currentCentury)
              {
                td.setAttribute("class", "datepicker-today");
              }
              td.setAttribute("ng-click", "setDate(" + k + ",'day')");

              $compile(td)($scope);
            }
            else
            {
              td.innerHTML = '';
              td.setAttribute("class", "datepicker-other-month");
            }
            tr.appendChild(td);
            j ++
          }
          dynamicPartTBody.appendChild(tr);
        }
        var pr = document.getElementById($scope.parentID);
        if(pr)
          pr.insertBefore(dynamicPart, pr.childNodes[pr.children.length - 1]);
    },

    createID = function()
    {
      if ($scope.parentID)
      {
        $scope.closeCalendar();
      }
      $scope.parentID = '_' + Math.random().toString(36).substr(2, 9);
      return $scope.parentID;
    },

    daysInMonth = function(monthIndex)
    {
      switch (monthIndex) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
          return 31;
          break;
        case 6:
        case 7:
        case 8:
        case 9:
        case 10:
          return 30;
          break;
        case 11:
          var currentRemain = $scope.currentYear % 33;
          if (remainingNumbers.indexOf(currentRemain) == -1)
          {
            console.info('natural');
            return 29;
          }
          else
          {
            console.info('leap');
            return 30;
          }
          break;
      }
    },

    calendarInitialize = function()
    {
      dateString = $scope.currentYear + '/' + ($scope.currentMonthIndex + 1) + '/' + '1';      
      if (calendarType == 'jalaali')
        m = moment(dateString, 'jYYYY/jM/jD/jdddd');
      else
        m = moment(dateString, 'YYYY/M/D/dddd');
      if (calendarType == 'jalaali')
        var x = moment(dateString, 'jYYYY/jM/jD');
      else
        var x = moment(dateString, 'YYYY/M/D');
      var y = x.format('dddd');
      if (calendarType == 'jalaali')
        $scope.monthFirstDayOfWeek = m.jMonth();
      else
        $scope.monthFirstDayOfWeek = m.month();
      if (calendarType == 'jalaali')
        $scope.numberOfDaysInMonth = daysInMonth($scope.currentMonthIndex);
      else
        $scope.numberOfDaysInMonth = m.daysInMonth($scope.currentMonthIndex);
      $scope.monthFirstDayOfWeek = monthFirstDayOfWeek(y);
      console.info('monthFirstDayOfWeek: ',$scope.monthFirstDayOfWeek);
      console.info('numberOfDaysInMonth: ',$scope.numberOfDaysInMonth);
      console.info('numberOfWeeksInMonth: ',numberOfWeeksInMonth($scope.monthFirstDayOfWeek));
      if(document.getElementById($scope.parentID + "dynamicPart"))
        document.getElementById($scope.parentID + "dynamicPart").remove();
      render4HTML();
    };

    $element.bind('click', function(){
      datePicker();
      $scope.$apply();
    });    
    
    var datePicker = function()
    {
      firstInitialize();
      var tempParent = document.createElement("div");
      tempParent.setAttribute("id", createID());
      tempParent.setAttribute("class", "iShia-datepicker");
      // tempParent.style.left = $element[0].offsetLeft + "px";
      console.info($element[0].offsetHeight + $element[0].offsetTop + "px");
      // tempParent.style.top = $element[0].offsetHeight + $element[0].offsetTop + "px";
      //////////////////////////////////////////////
      // create close button
      var tempCloseButtonDiv = document.createElement("div");
      tempCloseButtonDiv.setAttribute("class","datepicker-close-btn");
      var tempCloseButton = document.createElement("button");
      tempCloseButton.setAttribute("ng-click","closeCalendar();");
      tempCloseButton.setAttribute("class","fa fa-times close-icon");
      tempCloseButtonDiv.appendChild(tempCloseButton);
      //////////////////////////////////////////////
      
      // create switch button
      var tempSwitchButton = document.createElement("button");
      tempSwitchButton.setAttribute("class","fa fa-language language-btn" );
      // tempSwitchButton.innerHTML = 'lang';
      tempSwitchButton.setAttribute("ng-show", calendarsList.length > 1);
      tempSwitchButton.setAttribute("ng-click", "switchCalendarModel();");
      ///////////////////////////////////////////////
      
      // create century 
      var tempCenturyRow = document.createElement("div");
      tempCenturyRow.setAttribute("class", "datepicker-year");
      
      var tempCenturyButton1 = document.createElement("button");
      tempCenturyButton1.setAttribute("ng-click", "goNextCentury()");
      tempCenturyButton1.setAttribute("class", "datepicker-ctrl-btn fa fa-chevron-right datepicker-next");
      var tempCenturySpan = document.createElement("button");
      tempCenturySpan.innerHTML = "{{currentCentury | convertToParsiDigitFilter:this}}";
      tempCenturySpan.addEventListener("click", function(e)
      {
        $scope.setDate($scope.currentCentury, 'century');
      });
      tempCenturySpan.setAttribute("ng-click", "goPreviousCentury()");
      var tempCenturyButton2 = document.createElement("button");
      tempCenturyButton2.setAttribute("ng-click", "goPreviousCentury()");
      tempCenturyButton2.setAttribute("class", "datepicker-ctrl-btn fa fa-chevron-left datepicker-pre");
      tempCenturyRow.appendChild(tempCenturyButton1);
      tempCenturyRow.appendChild(tempCenturySpan);
      tempCenturyRow.appendChild(tempCenturyButton2);
      // create YEAR row
      var tempYearRow = document.createElement("div");
      tempYearRow.setAttribute("class", "datepicker-year");
      var yearTempButton1 = document.createElement("button");
      yearTempButton1.setAttribute("class", "datepicker-ctrl-btn fa fa-chevron-left datepicker-pre");
      yearTempButton1.setAttribute("ng-click", "goPreviousYear()");
      var yearTempSpan = document.createElement("button");
      yearTempSpan.innerHTML = "{{currentYear | convertToParsiDigitFilter:this}}";
      yearTempSpan.addEventListener("click", function(e)
      {
        $scope.setDate($scope.currentYear, 'year');
      });

      var yearTempButton2 = document.createElement("button");
      yearTempButton2.setAttribute("ng-click", "goNextYear()");
      yearTempButton2.setAttribute("class", "datepicker-ctrl-btn fa fa-chevron-right datepicker-next");
      tempYearRow.appendChild(yearTempButton1);
      tempYearRow.appendChild(yearTempSpan);
      tempYearRow.appendChild(yearTempButton2);
      //////////////////////////////////////////////
      // create MONTH row
      var tempMonthRow = document.createElement("div");
      tempMonthRow.setAttribute("class", "datepicker-month");
      var monthTempButton1 = document.createElement("button");
      monthTempButton1.setAttribute("class", "datepicker-ctrl-btn fa fa-chevron-left datepicker-pre");
      monthTempButton1.setAttribute("ng-click", "goPreviousMonth()");
      var monthTempSpan = document.createElement("span");
      monthTempSpan.addEventListener("click", function(e)
      {
        $scope.setDate($scope.currentMonthIndex, 'month');
      });
      monthTempSpan.innerHTML = "{{MonthArray[currentMonthIndex]}}";
      var monthTempButton2 = document.createElement("button");
      monthTempButton2.setAttribute("ng-click", "goNextMonth()");
      monthTempButton2.setAttribute("class", "datepicker-ctrl-btn fa fa-chevron-right datepicker-next");
      tempMonthRow.appendChild(monthTempButton1);
      tempMonthRow.appendChild(monthTempSpan);
      tempMonthRow.appendChild(monthTempButton2);
      /////////////////////////////////////////////
      // today's date
      var todayDate = document.createElement("div");
      todayDate.setAttribute("class", "datepicker-date-today");
      var todayDateSpan = document.createElement("span");
      todayDateSpan.setAttribute("class", "datepicker-icon fa fa-calendar");
      if($scope.calendarType == 'jalaali')
        var today = 'امروز';
      else if($scope.calendarType == 'gregorian')
        var today = 'today';        
      todayDateSpan.innerHTML = today + ' : {{currentYearIndex1 | convertToParsiDigitFilter:this}} {{MonthArray[currentMonthIndex1]}} {{currentDayIndex1 | convertToParsiDigitFilter:this}}';
      todayDate.appendChild(todayDateSpan);
      /////////////////////////////////////////////
      // append created elements to their parent
      tempParent.appendChild(tempSwitchButton);
      if(Boolean($attrs.displayCentury === "true")) {
        tempParent.appendChild(tempCenturyRow);
      }
      tempParent.appendChild(tempCloseButtonDiv);
      tempParent.appendChild(tempYearRow);
      tempParent.appendChild(tempMonthRow);
      tempParent.appendChild(todayDate);
      /////////////////////////////////////////////
      // compile and render
      var compiledTemplate = $compile(tempParent);
      var content = compiledTemplate($scope);
      var body = angular.element($document[0].body);
      var parent = $element.parent();
      parent.append(content);
      render4HTML();
    }

    $scope.switchCalendarModel = function()
    {
      switch(calendarType)
      {
        case 'jalaali':
          calendarType = 'gregorian';
          var tempFormat = $attrs.format.replaceAll('j','');
          $attrs.format = tempFormat;
          break;
        case 'gregorian':
          calendarType = 'jalaali';
          break;
      }
      datePicker();
    }

    $scope.fingilishToPersian = function(input)
    {
      input = input.replace('Farvardin', 'فروردین');
      input = input.replace('Ordibehesht', 'اردیبهشت');
      input = input.replace('Khordaad', 'خرداد');
      input = input.replace('Tir', 'تیر');
      input = input.replace('Amordaad', 'مرداد');
      input = input.replace('Shahrivar', 'شهریور');
      input = input.replace('Mehr', 'مهر');
      input = input.replace('Aabaan', 'آبان');
      input = input.replace('Aazar', 'آذر');
      input = input.replace('Dey', 'دی');
      input = input.replace('Bahman', 'بهمن');
      input = input.replace('Esfand', 'اسفند');
      return input;
    }

    $scope.setDate = function(value, mode)
    {
      switch(mode)
      {
        case 'century':
          $scope.dateString = $scope.currentCentury;
          $scope.$apply();
          break;
        
        case 'year':
          $scope.dateString = $scope.currentYear;
          $scope.$apply();
          break;
        
        case 'month':
          $scope.dateString = $scope.currentYear + '-' + ($scope.currentMonthIndex + 1);
          if (calendarType == 'gregorian')
          {
            var formatedDate = moment($scope.dateString).format($attrs.format.toString());
            $scope.dateString = formatedDate; 
          }
          $scope.$apply();
          break;  
        
        case 'day':
          $scope.dateString = $scope.currentYear + '-' + ($scope.currentMonthIndex + 1) + '-' + value;
          if (calendarType == 'gregorian')
          {
            var formatedDate = moment($scope.dateString).format($attrs.format.toString());
          }
          if (calendarType == 'jalaali')
          {
            var m = moment($scope.dateString, 'jYYYY/jM/jD');
            var formatedDate = m.format($attrs.format.trim());
          }
            $scope.dateString = $scope.fingilishToPersian(formatedDate);
          break;  
      }
      
      $scope.closeCalendar();
    }

    $scope.goNextCentury = function()
    {
      $scope.currentCentury ++;
      calendarInitialize();
    }

    $scope.goPreviousCentury = function()
    {
      if($scope.currentCentury != 0)
        $scope.currentCentury --;
      calendarInitialize();
    }

    $scope.goNextMonth = function()
    {
      if ($scope.currentMonthIndex == 11)
      {
        $scope.currentMonthIndex = 0;
        $scope.currentYear ++;
      }
      else
        $scope.currentMonthIndex ++;
      calendarInitialize();
    }

    $scope.goPreviousMonth = function()
    {
      if ($scope.currentMonthIndex == 0)
      {
        $scope.currentMonthIndex = 11;
        $scope.currentYear --;
      }
      else
        $scope.currentMonthIndex --;
      calendarInitialize();
    }

    $scope.goNextYear = function()
    {
      console.info($scope.currentYear);
      $scope.currentYear ++;
      if(calendarType == 'jalaali')
        $scope.currentYear = $scope.currentYear;
      calendarInitialize();
    }

    $scope.goPreviousYear = function()
    {
        $scope.currentYear --;
        calendarInitialize();
    }

    if(eval($scope.opendefault))
    {
      datePicker();
    }
    /////////////////////////////////////////////////////////////////////////
    };
    return {
      restrict: 'A',
      link: link,
      scope:{
        dateString: "=ngModel",
        opendefault: "@"
      }
    }
  }
  ;
  init();

})(this.angular);


/*

<div id="parent" style="position:relative;">
    <label class="ng-binding">میلادی:</label>
    <input placeholder="میلادی" ng-model="gregoriandate" ishia-datepicker="Gregorian" formatdate="format" opendefault="false" class="ng-pristine ng-valid ng-isolate-scope ng-touched">
    <span ng-show="showdate" class=""><label class="ng-binding">فارسی:</label> <input type="text" ng-model="persiondate" class="ng-pristine ng-valid ng-touched"> </span>
    <span ng-show="showdate" class=""><label class="ng-binding">عربی:</label> <input type="text" ng-model="arabicdate" class="ng-pristine ng-untouched ng-valid"> </span>
</div>

<div id="parent" class="datepicker-container">

  <div class="box form-group">
    <div class="col-xs-12 relative">
      <input class="form-control form-control box crs-input" placeholder="میلادی" ng-model="gregoriandate" ishia-datepicker="Gregorian" formatdate="format" opendefault="false">
      <label class="label">میلادی</label>
    </div>
  </div>

  <div class="box form-group" ng-show="showdate">
    <div class="col-xs-12 relative">
      <input class="form-control form-control box crs-input" ng-model="persiondate">  
      <label class="label">فارسی</label>
    </div>
  </div>  

  <div class="box form-group" ng-show="showdate">
    <div class="col-xs-12 relative">
      <input class="form-control form-control box crs-input" ng-model="arabicdate">  
      <label class="label">عربی</label>
    </div>
  </div>  

</div>

*/